<template>
  <div id="control-panel--color-switcher">
    <div class="label">Color theme selector</div>
    <p class="description">Click to change main color</p>
    <a href="javascript:;"
      v-for="(color, index) in colorPalette"
      v-bind:key="index"
      v-bind:title="color"
      v-on:click="changeColorTheme(color)">
      <div class="color" v-bind:style="{ backgroundColor: color, }"></div>
    </a>
  </div>
</template>

<script>
  import { setColorTheme } from '@/utils/frontend';
  import { firestore, } from "@/services/firebase.service";
  export default {
    computed: {
      colorPalette() {
        return this.$store.state.system.configs.THEME.color.palette;
      },
    },
    methods: {
      async changeColorTheme(color) {
        try {
          await firestore.doc("system/THEME").update({ "color.primary": color, });
          setColorTheme(color);
        } catch (e) {
          console.error(e);
          this.$swal(
            "Oops!",
            "An error has occurred",
            "error",
          );
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .color {
    border-radius: 4px;
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    width: 32px;
  }
</style>